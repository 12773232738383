<template>
  <div class="video-page">
    <template v-if="!loading">
      <div class="top-nav">
        <div class="content-wrap">
          当前位置：<span @click="toHome('')">首页</span><span @click="toBack()">{{ courseInfo.category && courseInfo.category.name ? ' > ' + courseInfo.category.name : '' }}</span> > <span @click="toBack()">{{ courseInfo.name }}</span>
        </div>
      </div>
      <div class="video-content">
        <div class="video-play-wrap" :style="{'width' : isOpen ? 'calc( 100% - 360px )' : '100%', 'margin-left' : isOpen ? '360px' : '0'}">
          <LivePlayer
            ref="livePlayer"
            fluent
            :videoUrl="videoUrl"
            hide-stretch-button
            hide-snapshot-button
            hide-big-play-button
            resolution='hd,sd'
            custom-buttons="下一课时,标清"
            :playback-rates="playbackRates"
            @customButtons="customButtonsAction"
            @play="play"
            @ended="playEnd"
            @pause="pause"
          />
          <div v-if="bLoading" class="loading-wrap" v-loading="bLoading" :loading.sync="bLoading" element-loading-text="加载中..." element-loading-background="#282828"></div>
        </div>
        <div v-if="!isOpen" class="open-btn" @click="isOpen = true">
          <img src="../assets/img/video_open_icon.png" />
        </div>
        <div v-if="isOpen" class="left-catalog">
          <div class="catalog-left">
            <div class="catalog-left-content">
              <img src="../assets/img/video_catalog_icon.png" />
              <span>目录</span>
            </div>
          </div>
          <div class="list-wrap">
            <el-tree
              ref="tree"
              :data="catalogData"
              highlight-current
              :props="defaultProps"
              node-key="id"
              :current-node-key="curCatalogID"
              :default-expanded-keys="[curCatalogID]"
              :default-checked-keys="[curCatalogID]"
              @node-click="handleNodeClick"
            >
              <div class="catalog-wrap" slot-scope="{ node, data }">
                <div class="catalog-wrap-item" v-if="data.children && data.children.length > 0">
                  <span :id="data.id" class="catalog-name">{{ node.label }}</span>
                </div>
                <div class="catalog-wrap-item catalog-wrap-last" v-else>
                  <template v-if="data.video">
                    <div class="top">
                      <div class="top-image" />
                      <span :id="data.id" class="catalog-name">{{ node.label }}</span>
                      <template v-if="data.video && data.video.is_over === 1">
                        <img class="video-view-process" src="../assets/img/video_over.png" />
                      </template>
                      <template v-else>
                        <img class="video-view-process" v-if="data.video.video_times > 0 && data.video.video_times < data.video.video_duration" src="../assets/img/video_playing.png" />
                        <img class="video-view-process" v-else-if="data.video.video_times >= data.video.video_duration" src="../assets/img/video_over.png" />
                        <img class="video-view-process" v-else src="../assets/img/video_no_view.png" />
                      </template>
                    </div>
                    <div class="bottom">
                      <span>录播</span>
<!--                      <div>{{ data.video.video_times > data.video.video_duration ? formatTime(data.video.video_duration) : data.id === curCatalogID ? formatTime(parseInt(this.$refs.livePlayer.getCurrentTime())) : formatTime(data.video.video_times)}} / {{ formatTime(data.video.video_duration) }}</div>-->

                                            <div>{{ data.video.video_times > data.video.video_duration ? formatTime(data.video.video_duration) : formatTime(data.video.video_times) }} / {{ formatTime(data.video.video_duration) }}</div>
                    </div>
                  </template>
                </div>
              </div>
            </el-tree>
          </div>
          <div class="close-btn" @click="isOpen = false">
            <img src="../assets/img/video_close_icon.png" />
          </div>
        </div>
      </div>
    </template>
    <application-dialog :application-dialog="applicationDialog" @hideDialog="hideApplicationDialog" />
    <download-dialog :download-dialog="downloadDialog" @hideDialog="hideDownloadDialog" />
    <loading :is-load="loading" />
    <div v-if="showFluent" class="video-fluent">
      <span :class="{'selected' : fluent === '标清'}" @click="checkFluent('标清')">标清</span>
      <span :class="{'selected' : fluent === '高清'}" @click="checkFluent('高清')">高清</span>
    </div>
  </div>
</template>

<script>
import LivePlayer from '@liveqing/liveplayer';
import applicationDialog from '@/views/modules/application-dialog';
import downloadDialog from '@/views/modules/download-dialog';
import * as courseService from '@/service/course-service';
import { Message } from 'element-ui';
export default {
  name: 'videoPlayer',
  components: {
    LivePlayer,
    applicationDialog,
    downloadDialog
  },
  data() {
    return {
      bLoading: true,
      loading: false,
      isOpen: true,
      applicationDialog: false,
      downloadDialog: false,
      catalogData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      playbackRates: [0.75, 1.0, 1.25, 1.5, 2.0], // 视频播放倍速
      courseInfo: {}, // 课程详情
      curCatalogID: '', // 当前选中视频章节id
      curCatalogInfo: {}, // 当前选中视频
      videoUrl: '', // 当前视频url
      isTry: false, // 是否试看
      nextCatalog: {}, // 下一课时
      showFluent: false,
      fluent: '标清',
      saveLogTimeInterval: null,
      timeInterval: null
    };
  },
  created() {
    this.getCourseInfo();
    this.isTry = !!+this.$route.query.isTry;
  },
  mounted() {
    this.bLoading = true;
    document.addEventListener('keydown', this.keydown, true);
  },
  methods: {
    keydown(event) {
      const time = 10;
      event.stopPropagation();
      if (this.$refs.livePlayer) {
        if (event.keyCode === 39) {
          clearInterval(this.timeInterval);
          this.timeInterval = null;
          this.$refs.livePlayer.setCurrentTime(this.$refs.livePlayer.getCurrentTime() + time);
          this.playTimeUpdate();
        }
        // 后退
        if (event.keyCode === 37) {
          clearInterval(this.timeInterval);
          this.timeInterval = null;
          this.$refs.livePlayer.setCurrentTime(this.$refs.livePlayer.getCurrentTime() - time);
          this.playTimeUpdate();
        }
        if (event.keyCode === 32) {
          if (this.$refs.livePlayer.paused()) {
            this.$refs.livePlayer.play();
          }
          else {
            this.$refs.livePlayer.pause();
          }
        }
        if (event.keyCode === 38) {
          this.$refs.livePlayer.setVolume(this.$refs.livePlayer.getVolume() + 0.1);
        }
        if (event.keyCode === 40) {
          this.$refs.livePlayer.setVolume(this.$refs.livePlayer.getVolume() - 0.1);
        }
      }
    },
    play() {
      this.bLoading = false;
      if (!this.saveLogTimeInterval) {
        this.saveLogTimeInterval = setInterval(() => {
          this.setUserLiveLog();
        }, 5000);
      }

      if (this.$refs.livePlayer.getMuted()) {
        setTimeout(() => {
          this.$refs.livePlayer.setMuted(false);
        }, 500);
      }
    },
    /**
     * 暂停
     * */
    pause() {
      clearInterval(this.saveLogTimeInterval);
      this.saveLogTimeInterval = null;
    },
    /**
     * 查询课程详情
     * */
    getCourseInfo() {
      const data = {
        id: this.$route.query.id
      };
      this.loading = true;
      courseService.getCourseInfo(data).then(res => {
        this.loading = false;
        if (res.code === 1) {
          this.courseInfo = res.data;
          // course_user1_count > 0 已经报名或者已经购买 == 0 未报名或未购买
          if (this.courseInfo.course_user1_count > 0) {
            this.isTry = false;
          }
          this.catalogData = res.data.outline;
          clearInterval(this.timeInterval);
          this.timeInterval = null;
          this.$nextTick(() => {
            this.bLoading = true;
            this.curCatalogID = this.$route.query.catalogID;
            this.$refs.tree.setCurrentKey(this.curCatalogID);
            this.curCatalogInfo = this.$refs.tree.getNode(this.curCatalogID).data;
            this.videoUrl = this.curCatalogInfo.video.video_url;
            setTimeout(() => {
              // 设置上次播放时间
              if (this.curCatalogInfo.video.video_duration - this.curCatalogInfo.video.video_times > 1) {
                this.$refs.livePlayer.setCurrentTime(this.curCatalogInfo.video.video_times);
              }
              // this.$refs.livePlayer.setCurrentTime(this.curCatalogInfo.video.video_times);
              this.playTimeUpdate();
            }, 1000);
            if (!this.isTry) {
              this.getAfterNode(this.catalogData, this.curCatalogInfo);
            }
          });
        }
      });
    },
    async handleNodeClick(data) {
      if (!data.children || data.children.length === 0) {
        this.getAfterNode(this.catalogData, data);
        // 保存用户观看记录
        await this.setUserLiveLog();
        // 未购买
        if (this.courseInfo.course_user1_count === 0 && data.is_try !== 1) {
          // 立即购买
          if (this.courseInfo.is_free === 0) {
            this.downloadDialog = true;
          }
          // 立即报名
          else {
            this.applicationDialog = true;
          }
          return;
        }
        clearInterval(this.saveLogTimeInterval);
        this.saveLogTimeInterval = null;
        clearInterval(this.timeInterval);
        this.timeInterval = null;
        this.bLoading = true;
        this.curCatalogID = data.id;
        this.videoUrl = data.video.video_url;
        this.curCatalogInfo = data;
        if (!this.isTry) {
          setTimeout(() => {
            // 设置上次播放时间
            if (this.curCatalogInfo.video.video_duration - this.curCatalogInfo.video.video_times > 1) {
              this.$refs.livePlayer.setCurrentTime(this.curCatalogInfo.video.video_times);
            }
            // this.$refs.livePlayer.setCurrentTime(this.curCatalogInfo.video.video_times);
            this.playTimeUpdate();
          }, 1000);
        }
      }
    },
    playTimeUpdate() {
      // if (!this.timeInterval) {
      //   this.timeInterval = setInterval(() => {
      //     const curTime = parseInt(this.$refs.livePlayer.getCurrentTime());
      //     this.reWriteVideoTime(this.catalogData, curTime);
      //   }, 1000);
      // }
    },
    customButtonsAction(e) {
      if (e === '下一课时') {
        this.showFluent = false;
        if (!this.isTry) {
          clearInterval(this.timeInterval);
          this.timeInterval = null;
          this.setUserLiveLog();
          this.curCatalogID = this.nextCatalog.id;
          this.videoUrl = this.nextCatalog.video.video_url;
          this.$refs.tree.setCurrentKey(this.curCatalogID);
          this.curCatalogInfo = this.nextCatalog;
          setTimeout(() => {
            // 设置上次播放时间
            if (this.curCatalogInfo.video.video_duration - this.curCatalogInfo.video.video_times > 1) {
              this.$refs.livePlayer.setCurrentTime(this.curCatalogInfo.video.video_times);
            }
            // this.$refs.livePlayer.setCurrentTime(this.curCatalogInfo.video.video_times);
            this.playTimeUpdate();
          }, 1000);
          this.getAfterNode(this.catalogData, this.$refs.tree.getNode(this.curCatalogID).data);
        }
        else {
          Message({
            message: '此视频为试看',
            type: 'warning',
            duration: 1500,
            customClass: 'element-error-message-zindex'
          });
        }
      }
      else if (e === '标清' || e === '高清') {
        this.showFluent = !this.showFluent;
      }
    },
    /**
     * 选择清晰度
     * */
    checkFluent(title) {
      this.fluent = title;
      this.showFluent = false;
      const buttons = document.querySelectorAll('.vjs-button');
      buttons.forEach(item => {
        if (item.innerHTML === '标清' || item.innerHTML === '高清') {
          item.innerHTML = title;
        }
      });
    },
    getAfterNode(data, node) { // 获取同级后一个节点，data整棵树节点，node当前节点
      let obj = {};
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === node.id) {
          if (i < (data.length - 1)) {
            obj = data[i + 1];
            if (obj.children && obj.children.length > 0) {
              if (obj.children[0].children && obj.children[0].children.length > 0) {
                if (obj.children[0].children[0].children && obj.children[0].children[0].children.length > 0) {
                  this.nextCatalog = obj.children[0].children[0].children[0];
                }
                else {
                  this.nextCatalog = obj.children[0].children[0];
                }
              }
              else {
                this.nextCatalog = obj.children[0];
              }
            }
            else {
              this.nextCatalog = obj;
            }
            return;
          }
          else { // 没有后面一个节点
            if (this.$refs.tree.getNode(data[i].parent_id)) {
              this.getAfterNode(this.catalogData, this.$refs.tree.getNode(data[i].parent_id).data);
            }
          }
        }
        else if (data[i].children) { // 有下级，递归查询
          this.getAfterNode(data[i].children, node);
        }
      }
    },
    /**
     * 视频播放结束
     * */
    playEnd() {
      this.getAfterNode(this.catalogData, this.$refs.tree.getNode(this.curCatalogID).data);
      this.setUserLiveLog(1);
      clearInterval(this.timeInterval);
      this.timeInterval = null;
      this.curCatalogID = this.nextCatalog.id;
      this.videoUrl = this.nextCatalog.video.video_url;
      this.$refs.tree.setCurrentKey(this.curCatalogID);
      this.curCatalogInfo = this.nextCatalog;
      setTimeout(() => {
        // 设置上次播放时间
        if (this.curCatalogInfo.video.video_duration - this.curCatalogInfo.video.video_times > 1) {
          this.$refs.livePlayer.setCurrentTime(this.curCatalogInfo.video.video_times);
        }
        this.playTimeUpdate();
      }, 1000);
    },
    /**
     * 保存用户观看记录
     * */
    setUserLiveLog(isOver) {
      if (!this.isTry) {
        const curTime = parseInt(this.$refs.livePlayer.getCurrentTime());
        this.reWriteVideoTime(this.catalogData, curTime);
        const params = {
          is_over: isOver || curTime >= this.curCatalogInfo.video.video_duration ? 1 : 0,
          speed: `${(curTime / this.curCatalogInfo.video.video_duration).toFixed(2) * 100}%`,
          chapter_id: this.curCatalogInfo.video.id,
          video_times: curTime
        };
        courseService.addUserLiveLog(params).then(res => {
          if (res.code === 1) {
            // this.reWriteVideoTime(this.catalogData, curTime);
          }
        });
      }
    },
    reWriteVideoTime(data, time) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === this.curCatalogID || data[i].id.toString() === this.curCatalogID) {
          data[i].video.video_times = time;
        }
        else if (data[i].children) {
          this.reWriteVideoTime(data[i].children, time);
        }
      }
    },
    /**
     * 视频时长转换
     * */
    formatTime(duration) {
      const hour = parseInt(duration / 3600);
      const minute = parseInt((duration % 3600) / 60) > 9 ? parseInt((duration % 3600) / 60) : '0' + parseInt((duration % 3600) / 60);
      const seconds = parseInt(duration % 60) > 9 ? parseInt(duration % 60) : '0' + parseInt(duration % 60);
      const timeStr = `${hour}:${minute}:${seconds}`;
      return timeStr;
    },
    /**
     * 隐藏报名弹窗
     * */
    hideApplicationDialog() {
      this.applicationDialog = false;
    },
    /**
     * 隐藏app弹窗
     * */
    hideDownloadDialog() {
      this.downloadDialog = false;
    },
    /**
     * 首页
     * */
    toHome() {
      this.$router.push({
        path: '/'
      });
    },
    /**
     * 返回课程详情
     * */
    toBack() {
      this.$router.go(-1);
    }
  },
  beforeDestroy() {
    this.setUserLiveLog();
    this.saveLogTimeInterval = null;
    clearInterval(this.saveLogTimeInterval);
    this.videoUrl = '';
    document.removeEventListener('keydown');
  },
  destroyed() {
    this.setUserLiveLog();
    this.saveLogTimeInterval = null;
    clearInterval(this.saveLogTimeInterval);
    this.videoUrl = '';
    document.removeEventListener('keydown');
  }
};
</script>

<style lang="scss">
.video-page {
  width: 100%;
  height: 100vh;
  background: #2F2F2F;
  display: flex;
  flex-direction: column;
  .top-nav {
    background: #282828;
    width: 100%;
    .content-wrap {
      display: flex;
      align-items: center;
      height: 40px;
      font-size: 14px;
      color: #E0E0E0;
      span {
        cursor: pointer;
      }
    }
  }
  .video-content {
    display: flex;
    flex: 1;
    min-width: 1200px;
    position: relative;
    .left-catalog {
      display: flex;
      width: 360px;
      height: calc( 100% - 42px );
      background: #282828;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 100;
      .catalog-left {
        .catalog-left-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 80px;
          background: #2F2F2F;
          img {
            width: 16px;
            height: 16px;
            margin-bottom: 8px;
          }
          span {
            font-size: 14px;
            color: #FFFFFF;
          }
        }
      }
      .list-wrap {
        flex: 1;
        background: #2F2F2F;
        padding-top: 20px;
        height: 100%;
        overflow-y: auto;
        .el-tree {
          >.el-tree-node {
            padding: 0 21px 0 12px;
            background: #2F2F2F;
          }
          .el-tree-node {
            white-space: unset;
          }
          .catalog-wrap {
            width: 100%;
          }
          .catalog-wrap-item {
            padding-right: 15px;
            .catalog-name {
              font-size: 14px;
              font-weight: 400;
              color: #FFFFFF;
            }
          }
          .catalog-wrap-last {
            .top {
              display: flex;
              .top-image {
                width: 7px;
                height: 11px;
                margin-right: 8px;
                background: url("../assets/img/video_pause_icon.png") no-repeat 100% 100%;
              }
              .catalog-name {
                font-size: 14px;
                font-weight: 400;
                color: #C3C5D9;
              }
              .video-view-process {
                width: 16px;
                height: 16px;
                margin-left: 10px;
              }
            }
            .bottom {
              display: flex;
              align-items: center;
              font-size: 12px;
              font-weight: 400;
              color: #77777D;
              padding-left: 15px;
              margin-top: 10px;
              span {
                margin-right: 28px;
              }
            }
          }
          .el-tree-node__children {
            .catalog-name {
              font-size: 14px;
            }
          }
          .el-tree-node__content {
            position: relative;
            width: 100%;
            height: auto;
            line-height: 1;
            padding: 12px 0;
          }
          .el-tree-node__expand-icon {
            position: absolute;
            right: 0;
            top: calc( 50% - 3.5px );
          }
          .el-tree-node__expand-icon.is-leaf {
            display: none;
          }
          .el-tree-node__expand-icon:before {
            content: '';
          }
          .el-tree-node__expand-icon {
            background: url("../assets/img/video_down_icon.png") no-repeat 100% 100%;
            background-size: 13px 7px;
          }
          .el-tree-node__expand-icon.expanded {
            transform: rotate(0);
            background: url("../assets/img/video_up_icon.png") no-repeat 100% 100%;
          }
          .el-tree-node__content:hover {
            background-color: transparent;
          }
          .el-tree-node:focus > .el-tree-node__content {
            background-color: transparent;
          }
        }
        .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
          background-color: transparent;
          .catalog-wrap-last {
            .top {
              .top-image {
                background: url("../assets/img/video_pause_selected.png") no-repeat 100% 100%;
              }
            }
            .catalog-name {
              color: #2669F4 !important;
            }
          }
        }
      }
      .close-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 30px;
        height: 60px;
        top: calc( 50% - 30px );
        right: -30px;
        background: #2F2F2F;
        border-radius: 0px 10px 10px 0px;
        cursor: pointer;
        img {
          width: 11px;
          height: 20px;
        }
      }
    }
    .video-play-wrap {
      background: #FFFFFF;
      width: 100%;
      height: 100%;
      .player-wrapper {
        height: 100%;
        .video-wrapper {
          height: 100%;
          padding-bottom: 0 !important;
          .video-js .vjs-control-bar {
            background-color: #282828;
          }
          .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
            opacity: 1;
          }
          .video-js .vjs-slider {
            background-color: #6F859F;
          }
          .video-js .vjs-play-progress {
            background-color: #00A4FF;
          }
          .video-js .vjs-control {
            width: 5em;
          }
          .vjs-duration, .vjs-current-time {
            width: 4em !important;
          }
          .vjs-progress-control {
            min-width: 5em;
          }
          .vjs-playback-rate-value {
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #FFFFFF;
          }
          .vjs-menu {
            font-size: 12px;
            color: #FFFFFF;
            .vjs-menu-content {
              display: flex;
              justify-content: space-around;
              flex-direction: column;
              width: 64px;
              min-height: 150px;
              background: rgba(0, 0, 0, 0.6);
              border-radius: 4px;
              max-height: unset;
            }
            .vjs-selected {
              background-color: transparent;
              color: #3778FF;
            }
          }
          .vjs-menu li {
            line-height: 1.8em;
          }
          .video-js .vjs-volume-panel .vjs-volume-control {
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1000;
            background: rgba(0, 0, 0, 0.6);
            width: 40px;
            height: 150px;
            border-radius: 4px;
            bottom: 146px;
          }
          .video-page .video-content .video-play-wrap .player-wrapper .video-wrapper .video-js .vjs-slider {
            background-color: #70717B;
          }
          .vjs-volume-bar.vjs-slider-vertical {
            height: 120px;
          }
          .video-js .vjs-volume-level {
            background-color: #3778FF;
          }
        }
      }
      .loading-wrap {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 42px;
        width: 100%;
        z-index: 100;
      }
    }
    .open-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 30px;
      height: 60px;
      top: calc( 50% - 30px );
      left: 0;
      background: rgba(47, 47, 47, 0.3);
      border-radius: 0px 10px 10px 0px;
      cursor: pointer;
      img {
        width: 11px;
        height: 20px;
      }
    }
  }
  .video-fluent {
    position: absolute;
    right: 4em;
    bottom: 42px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    width: 5em;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    span {
      display: flex;
      justify-content: center;
      width: 100%;
      flex: 1;
      text-align: center;
      font-size: 12px;
      color: #FFFFFF;
      cursor: pointer;
      line-height: 30px;
      height: 30px;
    }
    span:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
    .selected {
      background-color: transparent;
      color: #3778FF;
    }
  }
}
</style>
